import React from 'react'

function Congratulation(props) {
  const { icon, title, text, cardButton } = props

  return (
    <div className="custom_card">
      <div className="custom_card_body ptb60">
        <div className='row justify-content-center'>
          <div className="col-lg-8">
            <div className="icon_wrapper mb-4 text-center">
              {icon ? icon : ""}
            </div>
            <h6 className='mb-3 text-center'>{title ? title : ""}</h6>
            <p className='fs-16 fw-600 text_black60 text-center mb-4'>{text ? text : ""}</p>
            <div className="text-center flex_item_cc gap-4">
              {cardButton ? cardButton : ""}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Congratulation;
