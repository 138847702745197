
import React, { Suspense, lazy } from 'react'
// import Home from "../view/pages/front/Home";
// import MySearch from "../view/pages/front/mySearch/MySearch";
// import Prices from "../view/pages/front/Prices";
// import StyleGuides from "../view/pages/StyleGuides";
// import Faq from "../view/pages/front/Faq";
// import Login from "../view/pages/auth/Login";
// import Register from "../view/pages/auth/Register";
// import ForgetPass from "../view/pages/auth/ForgetPass";
// import ResetPassword from "../view/pages/auth/ResetPassword";
// import ForgetPasswordOtpVerify from "../view/pages/auth/ForgetPasswordOtpVerify";
// import ApplyForJob from "../view/pages/front/ApplyForJob";
// import SalonProfile from "../view/pages/vendor_panel/salonProfile/SalonProfile";
// import OtpVerification from "../view/pages/auth/OtpVerification";
// import RegisterProfileType from "../view/pages/auth/RegisterProfileType";
// import Profile from "../view/pages/vendor_panel/profiles/worker/Profile";
// import AddService from "../view/pages/vendor_panel/salonProfile/AddService";
// import WorkersEducation from "../view/pages/vendor_panel/salonProfile/WorkersEducation";
// import AddWorker from "../view/pages/vendor_panel/salonProfile/AddWorker";
// import AddSalonWorker from "../view/pages/vendor_panel/salonProfile/AddSalonWorker";
import { PATH_APPLYJOB, PATH_AUTH, PATH_CUSTOMER, PATH_PROVIDER, PATH_WORKER } from "./path";
import { UserType } from '../../SecurityConstant';
import Congratulation from '../view/common/Congratulation';
import CustomerCongratulations from '../view/pages/auth/CustomerCongratulations';
// import Dashboard from "../view/pages/vendor_panel/Dashboard";
// import WorkerDashboard from "../view/pages/vendor_panel/WorkerDashboard";
// import OwnerProfile from "../view/pages/vendor_panel/profiles/owner/OwnerProfile";
// import ErrorPage from "../view/pages/auth/ErrorPage";
// import AboutUs from "../view/pages/front/AboutUs";
// import ContactUs from "../view/pages/front/ContactUs";
// import SalonBadges from "../view/pages/vendor_panel/salonProfile/SalonBadges";
// import SalonsAndWorkers from "../view/pages/vendor_panel/salons_and_workers/SalonsAndWorkers";
// import AddSalon from "../view/pages/vendor_panel/salonProfile/AddSalon";
// import MyCustomer from "../view/pages/vendor_panel/myCustomer/MyCustomer";
// import JobPosted from "../view/pages/vendor_panel/jobPost/JobPosted";
// import CreateJobPost from "../view/pages/vendor_panel/jobPost/CreateJobPost";
// import Notification from "../view/pages/vendor_panel/Notification";
// import CustomerNotification from "../view/pages/customer_panel/Notification";
// import WorkerNotification from "../view/pages/vendor_panel/profiles/worker/Notification";
// import ChangePassword from "../view/pages/vendor_panel/profiles/worker/ChangePassword";
// import Subscription from "../view/pages/vendor_panel/subscription/Subscription";
// import ContactSupport from "../view/pages/vendor_panel/contactSupport/ContactSupport";
// import CustomerContactSupport from "../view/pages/customer_panel/contactSupport/contactSupport";
// import CustomerTicketReply from "../view/pages/customer_panel/contactSupport/CustomerTicketReply";
// import VendorTicketReply from "../view/pages/vendor_panel/contactSupport/ticketReply";
// import WorkerticketReply from "../view/pages/vendor_panel/contactSupport/WorkerticketReply";
// import Chat from "../view/pages/vendor_panel/messages/Chat";
// import CustomerDashboard from "../view/pages/customer_panel/Dashboard";
// import FindService from "../view/pages/customer_panel/FindService";
// import CustomerSalonProfile from "../view/pages/customer_panel/salonProfile/SalonProfile";
// import CustomerSalonDetails from "../view/pages/customer_panel/salonProfile/SalonProfileDetails";
// import BookingProcess from "../view/pages/customer_panel/bookingProcessSingle/BookingProcess";
// import CustomerProfile from "../view/pages/customer_panel/profiles/CustomerProfile";
// import Favorites from "../view/pages/customer_panel/Favorites";
// import MyBooking from "../view/pages/customer_panel/myBookings/MyBooking";
// import PaymentInfo from "../view/pages/customer_panel/myBookings/PaymentInfo";
// import CustomerRatingReview from "../view/pages/customer_panel/myBookings/CustomerRatingReview";
// import YourOrder from "../view/pages/customer_panel/myBookings/YourOrder";
// import BookingCalendar from "../view/pages/vendor_panel/bookingCalendar/BookingCalendar";
// import AddBooking from "../view/pages/vendor_panel/bookingCalendar/AddBooking";
// import UnderConstruction from "../view/pages/auth/UnderConstruction";
// import AddNewServieces from "../view/pages/vendor_panel/salonProfile/addNewSalon/AddNewServieces";
// import WorkerProfile from "../view/pages/vendor_panel/salons_and_workers/WorkerProfile";
// import ApplyJob from "../view/pages/front/ApplyJob";
// import TermsAndConditions from "../view/pages/front/TermsAndConditions";
// import PrivacyPolicy from "../view/pages/front/PrivacyPolicy";
// import CustomerFindService from "../view/pages/front/mySearch/CustomerFindService";
// import FindSalonProfile from "../view/pages/front/mySearch/FindSalonProfile";
// import WorkerBadges from "../view/pages/vendor_panel/profiles/worker/WorkerBadges";
// import WorkerBookingCalendar from "../view/pages/vendor_panel/bookingCalendar/WorkerBookingCalendar";
// import WorkerContactSupport from "../view/pages/vendor_panel/contactSupport/WorkerContactSupport";
// import CreateBusiness from "../view/pages/vendor_panel/salonProfile/CreateBusiness";
// import BecomeWorker from "../view/pages/vendor_panel/salonProfile/BecomeWorker";
// import ApplyForJobProvider from "../view/pages/vendor_panel/ApplyForJobProvider";
// import WorkerSalonProfile from "../view/pages/vendor_panel/profiles/worker/WorkerSalonProfile";

const MySearch =  lazy(() => import("../view/pages/front/mySearch/MySearch"));
const Prices =  lazy(() => import("../view/pages/front/Prices"));
const StyleGuides =  lazy(() => import("../view/pages/StyleGuides"));
const Faq =  lazy(() => import("../view/pages/front/Faq"));

const Home =  lazy(() => import("../view/pages/front/Home"));
const Login =  lazy(() => import("../view/pages/auth/Login"));
const Register =  lazy(() => import("../view/pages/auth/Register"));
const ForgetPass =  lazy(() => import("../view/pages/auth/ForgetPass"));
const ResetPassword =  lazy(() => import("../view/pages/auth/ResetPassword"));
const ForgetPasswordOtpVerify =  lazy(() => import("../view/pages/auth/ForgetPasswordOtpVerify"));
const ApplyForJob =  lazy(() => import("../view/pages/front/ApplyForJob"));
const SalonProfile =  lazy(() => import("../view/pages/vendor_panel/salonProfile/SalonProfile"));
const OtpVerification =  lazy(() => import("../view/pages/auth/OtpVerification"));
const RegisterProfileType =  lazy(() => import("../view/pages/auth/RegisterProfileType"));
const Profile =  lazy(() => import("../view/pages/vendor_panel/profiles/worker/Profile"));
const AddService =  lazy(() => import("../view/pages/vendor_panel/salonProfile/AddService"));
const WorkersEducation =  lazy(() => import("../view/pages/vendor_panel/salonProfile/WorkersEducation"));
const AddWorker =  lazy(() => import("../view/pages/vendor_panel/salonProfile/AddWorker"));
const AddSalonWorker =  lazy(() => import("../view/pages/vendor_panel/salonProfile/AddSalonWorker"));
const Dashboard =  lazy(() => import("../view/pages/vendor_panel/Dashboard"));
const WorkerDashboard =  lazy(() => import("../view/pages/vendor_panel/WorkerDashboard"));
const OwnerProfile =  lazy(() => import("../view/pages/vendor_panel/profiles/owner/OwnerProfile"));
const ErrorPage =  lazy(() => import("../view/pages/auth/ErrorPage"));
const AboutUs =  lazy(() => import("../view/pages/front/AboutUs"));
const ContactUs =  lazy(() => import("../view/pages/front/ContactUs"));
const SalonBadges =  lazy(() => import("../view/pages/vendor_panel/salonProfile/SalonBadges"));
const SalonsAndWorkers =  lazy(() => import("../view/pages/vendor_panel/salons_and_workers/SalonsAndWorkers"));
const AddSalon =  lazy(() => import("../view/pages/vendor_panel/salonProfile/AddSalon"));
const MyCustomer =  lazy(() => import("../view/pages/vendor_panel/myCustomer/MyCustomer"));
const JobPosted =  lazy(() => import("../view/pages/vendor_panel/jobPost/JobPosted"));
const CreateJobPost =  lazy(() => import("../view/pages/vendor_panel/jobPost/CreateJobPost"));
const Notification =  lazy(() => import("../view/pages/vendor_panel/Notification"));
const CustomerNotification =  lazy(() => import("../view/pages/customer_panel/Notification"));
const WorkerNotification =  lazy(() => import("../view/pages/vendor_panel/profiles/worker/Notification"));
const ChangePassword =  lazy(() => import("../view/pages/vendor_panel/profiles/worker/ChangePassword"));
const Subscription =  lazy(() => import("../view/pages/vendor_panel/subscription/Subscription"));
const ContactSupport =  lazy(() => import("../view/pages/vendor_panel/contactSupport/ContactSupport"));
const CustomerContactSupport =  lazy(() => import("../view/pages/customer_panel/contactSupport/contactSupport"));
const CustomerTicketReply =  lazy(() => import("../view/pages/customer_panel/contactSupport/CustomerTicketReply"));
const VendorTicketReply =  lazy(() => import("../view/pages/vendor_panel/contactSupport/ticketReply"));
const WorkerticketReply =  lazy(() => import("../view/pages/vendor_panel/contactSupport/WorkerticketReply"));
const Chat =  lazy(() => import("../view/pages/vendor_panel/messages/Chat"));
const CustomerDashboard =  lazy(() => import("../view/pages/customer_panel/Dashboard"));
const FindService =  lazy(() => import("../view/pages/customer_panel/FindService"));
const CustomerSalonProfile =  lazy(() => import("../view/pages/customer_panel/salonProfile/SalonProfile"));
const CustomerSalonDetails =  lazy(() => import("../view/pages/customer_panel/salonProfile/SalonProfileDetails"));
const BookingProcess =  lazy(() => import("../view/pages/customer_panel/bookingProcessSingle/BookingProcess"));
const CustomerProfile =  lazy(() => import("../view/pages/customer_panel/profiles/CustomerProfile"));
const Favorites =  lazy(() => import("../view/pages/customer_panel/Favorites"));
const MyBooking =  lazy(() => import("../view/pages/customer_panel/myBookings/MyBooking"));
const PaymentInfo =  lazy(() => import("../view/pages/customer_panel/myBookings/PaymentInfo"));
const CustomerRatingReview =  lazy(() => import("../view/pages/customer_panel/myBookings/CustomerRatingReview"));
const YourOrder =  lazy(() => import("../view/pages/customer_panel/myBookings/YourOrder"));
const BookingCalendar =  lazy(() => import("../view/pages/vendor_panel/bookingCalendar/BookingCalendar"));
const AddBooking =  lazy(() => import("../view/pages/vendor_panel/bookingCalendar/AddBooking"));
const UnderConstruction =  lazy(() => import("../view/pages/auth/UnderConstruction"));
const AddNewServieces =  lazy(() => import("../view/pages/vendor_panel/salonProfile/addNewSalon/AddNewServieces"));
const ContactSuportRequest =  lazy(() => import("../view/pages/vendor_panel/salonProfile/addNewSalon/ContactSuportRequest"));
const WorkerProfile =  lazy(() => import("../view/pages/vendor_panel/salons_and_workers/WorkerProfile"));
const ApplyJob =  lazy(() => import("../view/pages/front/ApplyJob"));
const TermsAndConditions =  lazy(() => import("../view/pages/front/TermsAndConditions"));
const PrivacyPolicy =  lazy(() => import("../view/pages/front/PrivacyPolicy"));
const CustomerFindService =  lazy(() => import("../view/pages/front/mySearch/CustomerFindService"));
const FindSalonProfile =  lazy(() => import("../view/pages/front/mySearch/FindSalonProfile"));
const WorkerBadges =  lazy(() => import("../view/pages/vendor_panel/profiles/worker/WorkerBadges"));
const WorkerBookingCalendar =  lazy(() => import("../view/pages/vendor_panel/bookingCalendar/WorkerBookingCalendar"));
const WorkerContactSupport =  lazy(() => import("../view/pages/vendor_panel/contactSupport/WorkerContactSupport"));
const CreateBusiness =  lazy(() => import("../view/pages/vendor_panel/salonProfile/CreateBusiness"));
const BecomeWorker =  lazy(() => import("../view/pages/vendor_panel/salonProfile/BecomeWorker"));
const ApplyForJobProvider =  lazy(() => import("../view/pages/vendor_panel/ApplyForJobProvider"));
const WorkerSalonProfile =  lazy(() => import("../view/pages/vendor_panel/profiles/worker/WorkerSalonProfile"));





const routes = [
    {
        path: PATH_AUTH.root,
        layout: 'front',
        exact: true,
        auth: true,
        MenuCollapsed: false,
        ActiveMenuKey: "",
        component: <Home title="Home" />
    },
    {
        path: PATH_AUTH.mySearch,
        layout: 'front',
        exact: true,
        auth: true,
        MenuCollapsed: false,
        ActiveMenuKey: "",
        component: <MySearch title="MySearch" />
    },
    {
        path: PATH_AUTH.findSalon,
        layout: 'front',
        exact: true,
        auth: true,
        MenuCollapsed: false,
        ActiveMenuKey: "",
        component: <CustomerFindService title="salon find" />
    },
    {
        path: PATH_AUTH.findSalonProfile,
        layout: 'front',
        exact: true,
        auth: true,
        MenuCollapsed: false,
        ActiveMenuKey: "",
        component: <FindSalonProfile title="salon find profile" />
    },
    {
        path: PATH_AUTH.shareSalonProfile,
        layout: 'front',
        exact: true,
        auth: true,
        MenuCollapsed: false,
        ActiveMenuKey: "",
        component: <FindSalonProfile title="salon find profile" />
    },
    {
        path: PATH_AUTH.prices,
        layout: 'front',
        exact: true,
        auth: true,
        MenuCollapsed: false,
        ActiveMenuKey: "",
        component: <Prices title="prices" />
    },
    {
        path: PATH_AUTH.faq,
        layout: 'front',
        exact: true,
        auth: true,
        MenuCollapsed: false,
        ActiveMenuKey: "",
        component: <Faq title="faq" />
    },
    {
        path: PATH_AUTH.aboutUs,
        layout: 'front',
        exact: true,
        auth: true,
        MenuCollapsed: false,
        ActiveMenuKey: "",
        component: <AboutUs title="about-us" />
    },
    {
        path: PATH_AUTH.termAndCondiotion,
        layout: 'front',
        exact: true,
        auth: true,
        MenuCollapsed: false,
        ActiveMenuKey: "",
        component: <TermsAndConditions title="terms-and-conditions" />
    },
    {
        path: PATH_AUTH.privacyPolicy,
        layout: 'front',
        exact: true,
        auth: true,
        MenuCollapsed: false,
        ActiveMenuKey: "",
        component: <PrivacyPolicy title="privacy-policy" />
    },
    {
        path: PATH_AUTH.contactUs,
        layout: 'front',
        exact: true,
        auth: true,
        MenuCollapsed: false,
        ActiveMenuKey: "",
        component: <ContactUs title="contact-us" />
    },
    {
        path: PATH_AUTH.login,
        layout: 'front',
        exact: true,
        auth: false,
        MenuCollapsed: false,
        ActiveMenuKey: "",
        component: <Login title="login" />
    },
    {
        path: PATH_AUTH.register,
        layout: 'front',
        exact: true,
        auth: false,
        MenuCollapsed: false,
        ActiveMenuKey: "",
        component: <Register title="register" />
    },
    {
        path: PATH_AUTH.forgetPassword,
        layout: 'front',
        exact: true,
        auth: false,
        MenuCollapsed: false,
        ActiveMenuKey: "",
        component: <ForgetPass title="forget-password" />
    },
    {
        path: PATH_AUTH.forgetPasswordOtpVerify,
        layout: 'front',
        exact: true,
        auth: false,
        MenuCollapsed: false,
        ActiveMenuKey: "",
        component: <ForgetPasswordOtpVerify title="otp-verify" />
    },
    {
        path: PATH_AUTH.otpVerification,
        layout: 'front',
        exact: true,
        auth: false,
        MenuCollapsed: false,
        ActiveMenuKey: "",
        component: <OtpVerification title="otp-verification" />
    },
    {
        path: PATH_AUTH.congratulations,
        layout: 'customer',
        exact: true,
        auth: true,
        MenuCollapsed: false,
        isRegister:1,
        ActiveMenuKey: "",
        component: <CustomerCongratulations title="congratulations" />
    },
    {
        path: PATH_AUTH.resetPassword,
        layout: 'front',
        exact: true,
        auth: false,
        MenuCollapsed: false,
        ActiveMenuKey: "",
        component: <ResetPassword title="reset-password" />
    },
    {
        path: PATH_AUTH.applyForJob,
        layout: 'front',
        exact: true,
        auth: true,
        MenuCollapsed: false,
        ActiveMenuKey: "",
        component: <ApplyForJob title="applyforjob" />
    },
    {
        path: PATH_APPLYJOB.applyJob,
        layout: 'applyjob',
        exact: true,
        auth: true,
        MenuCollapsed: false,
        ActiveMenuKey: "",
        component: <ApplyJob title="Apply Job Dashboard" />
    },
    {
        path: PATH_APPLYJOB.applyJobWorker,
        layout: 'penal',
        exact: true,
        auth: true,
        authType: [UserType.WORKER],
        MenuCollapsed: false,
        IsProfile: [0, 1],
        ActiveMenuKey: ["menu1_6", "menuid6"],
        component: <ApplyJob title="Apply Job Worker" />
    },
    {
        path: PATH_PROVIDER.dashboard,
        layout: 'penal',
        exact: true,
        auth: true,
        authType: [UserType.WORKER,UserType.OWNER, UserType.ISMANAGER],
        MenuCollapsed: true,
        ActiveMenuKey: ["menu1", "menu1_1"],
        IsProfile: [1],
        IsManager: 1,
        component: <Dashboard title="Provider Dashboard" />
    },
    {
        path: PATH_WORKER.dashboard,
        layout: 'penal',
        exact: true,
        auth: true,
        authType: [UserType.WORKER],
        MenuCollapsed: false,
        IsProfile: [1],
        ActiveMenuKey: ["menu1", "menu1_1"],
        component: <Dashboard title="Worker Dashboard" />
    },
    {
        path: PATH_WORKER.TicketReplies,
        layout: 'penal',
        exact: true,
        auth: true,
        authType: [UserType.WORKER],
        MenuCollapsed: false,
        IsProfile: [1],
        ActiveMenuKey: ["menu1", "menu1_1"],
        component: <WorkerticketReply title="Ticket" />
    },
    {
        path: PATH_WORKER.profile,
        layout: 'penal',
        exact: true,
        auth: true,
        authType: [UserType.WORKER,UserType.OWNER],
        MenuCollapsed: false,
        IsProfile: [0, 1],
        ActiveMenuKey: ["menu1_2", "menu1"],
        component: <Profile title="Worker Profile" />
    },
    {

        path: PATH_WORKER.messages,
        layout: 'penal',
        exact: true,
        auth: true,
        authType: [UserType.WORKER],
        MenuCollapsed: false,
        IsProfile: [0, 1],
        ActiveMenuKey: ["menu1_7", "menuid7"],
        component: <Chat title="Messages" />
    },
    {
        path: PATH_WORKER.chatMessages,
        layout: 'penal',
        exact: true,
        auth: true,
        authType: [UserType.WORKER],
        MenuCollapsed: false,
        IsProfile: [0, 1],
        ActiveMenuKey: ["menu1_7", "menuid7"],
        component: <Chat title="Messages" />
    },
    {

        path: PATH_WORKER.badges,
        layout: 'penal',
        exact: true,
        auth: true,
        authType: [UserType.WORKER],
        MenuCollapsed: false,
        IsProfile: [0, 1],
        ActiveMenuKey: ["menu1_8", "menuid8"],
        component: <WorkerBadges title="Salon Badges" />
    },
    {

        path: PATH_WORKER.applyForJob,
        layout: 'penal',
        exact: true,
        auth: true,
        authType: [UserType.WORKER],
        MenuCollapsed: false,
        IsProfile: [0, 1],
        ActiveMenuKey: ["menu1_6", "menuid6"],
        component: <ApplyForJobProvider title="Job Application" />
    },
    {

        path: PATH_WORKER.bookingCalendar,
        layout: 'penal',
        exact: true,
        auth: true,
        authType: [UserType.WORKER],
        MenuCollapsed: false,
        IsProfile: [0, 1],
        ActiveMenuKey: ["menu1_4", "menuid4"],
        component: <WorkerBookingCalendar title="Booking Calendar" />
    },

    {
        path: PATH_WORKER.notification,
        layout: 'penal',
        exact: true,
        auth: true,
        authType: [UserType.WORKER],
        MenuCollapsed: false,
        IsProfile: [0, 1],
        ActiveMenuKey: ["menu2_1", "menuid2_1"],
        component: <WorkerNotification title="Notification" />
    },

    {
        path: PATH_WORKER.changePassword,
        layout: 'penal',
        exact: true,
        auth: true,
        authType: [UserType.WORKER],
        MenuCollapsed: false,
        IsProfile: [0, 1],
        // ActiveMenuKey: ["menu2_1", "menuid2_1"],
        component: <ChangePassword title="ChangePassword" />
    },

    {
        path: PATH_PROVIDER.profile,
        layout: 'penal',
        exact: true,
        auth: true,
        authType: [UserType.OWNER, UserType.ISMANAGER],
        MenuCollapsed: true,
        ActiveMenuKey: ["menu1_2", "menu1"],
        IsProfile: [1],
        component: <OwnerProfile title="Provider Profile" />
    },
    {
        path: PATH_AUTH.registerProfile,
        layout: 'penal',
        exact: true,
        auth: true,
        authType: [UserType.WORKER, UserType.OWNER],
        MenuCollapsed: false,
        ActiveMenuKey: ["menu1_2", "menu1"],
        IsProfile: [0],
        component: <RegisterProfileType title="Register Profile" />
    },
    {
        path: PATH_AUTH.createBusiness,
        layout: 'penal',
        exact: true,
        auth: true,
        authType: [UserType.WORKER, UserType.OWNER],
        MenuCollapsed: false,
        ActiveMenuKey: ["menu1_2", "menu1"],
        IsProfile: [0],
        component: <CreateBusiness title="Create Business" />
    },

    {
        path: PATH_PROVIDER.salonProfile,
        layout: 'penal',
        exact: true,
        auth: true,
        authType: [UserType.OWNER, UserType.ISMANAGER],
        MenuCollapsed: true,
        ActiveMenuKey: ["menu1_3", "menu1"],
        IsProfile: [0,1],
        component: <SalonProfile title="Provider Salon Profile" />
    },
    {
        path: PATH_WORKER.salonProfile,
        layout: 'penal',
        exact: true,
        auth: true,
        authType: [UserType.OWNER,UserType.WORKER],
        MenuCollapsed: false,
        ActiveMenuKey: ["menu1_3", "menu1"],
        IsProfile: [1],
        component: <WorkerSalonProfile title="Worker Salon Profile" />
    },


    {
        path: PATH_PROVIDER.addService,
        layout: 'penal',
        exact: true,
        auth: true,
        authType: [UserType.OWNER, UserType.ISMANAGER],
        MenuCollapsed: true,
        ActiveMenuKey: ["menu1_3", "menu1"],
        IsProfile: [0],
        component: <AddService title="Salon Add Service" />
    },
    {
        path: PATH_PROVIDER.service,
        layout: 'penal',
        exact: true,
        auth: true,
        authType: [UserType.OWNER, UserType.ISMANAGER],
        MenuCollapsed: true,
        ActiveMenuKey: ["menu1_5", "menu1"],
        IsProfile: [1],
        component: <AddNewServieces title="Salon Add Service" />
    },
    {
        path: PATH_PROVIDER.request,
        layout: 'penal',
        exact: true,
        auth: true,
        authType: [UserType.OWNER, UserType.ISMANAGER],
        MenuCollapsed: true,
        ActiveMenuKey: ["menu1_5", "menu1"],
        IsProfile: [0,1],
        component: <ContactSuportRequest title="contact Suport" />
    },
    {
        path: PATH_PROVIDER.workersEducation,
        layout: 'penal',
        exact: true,
        auth: true,
        authType: [UserType.OWNER, UserType.ISMANAGER],
        MenuCollapsed: true,
        ActiveMenuKey: ["menu1_3", "menu1"],
        IsProfile: [1],
        component: <WorkersEducation title="Provider Workers Education" />
    },

    {
        path: PATH_PROVIDER.bookingCalendar,
        layout: 'penal',
        exact: true,
        auth: true,
        authType: [UserType.OWNER, UserType.ISMANAGER],
        MenuCollapsed: true,
        ActiveMenuKey: ["menu1_4", "menu1"],
        IsProfile: [1],
        component: <BookingCalendar title="Booking Calendar" />
    },

    {
        path: PATH_PROVIDER.addBooking,
        layout: 'penal',
        exact: true,
        auth: true,
        authType: [UserType.OWNER, UserType.ISMANAGER],
        MenuCollapsed: true,
        ActiveMenuKey: ["menu1_4", "menu1"],
        IsProfile: [1],
        component: <AddBooking title="Add Booking" />
    },

    {
        path: PATH_PROVIDER.addWorker,
        layout: 'penal',
        exact: true,
        auth: true,
        authType: [UserType.OWNER, UserType.ISMANAGER],
        MenuCollapsed: true,
        ActiveMenuKey: ["menu1_3", "menu1"],
        IsProfile: [1],
        component: <AddWorker title="Provider Add Workers" />
    },
    {
        path: PATH_PROVIDER.salonAndWorker,
        layout: 'penal',
        exact: true,
        auth: true,
        authType: [UserType.OWNER, UserType.ISMANAGER],
        MenuCollapsed: true,
        ActiveMenuKey: ["menu1_5", "menu1"],
        IsProfile: [1],
        IsManager: 0,
        component: <SalonsAndWorkers title="Salon And Worker" />
    },
    {
        path: PATH_WORKER.salonAndWorker,
        layout: 'penal',
        exact: true,
        auth: true,
        authType: [UserType.WORKER, UserType.ISMANAGER],
        MenuCollapsed: false,
        ActiveMenuKey: ["menu1_5", "menu1"],
        IsProfile: [1],
        IsManager: 1,
        component: <SalonsAndWorkers title="Salon And Worker" />
    },
    {
        path: PATH_PROVIDER.workerProfile,
        layout: 'penal',
        exact: true,
        auth: true,
        authType: [UserType.OWNER, UserType.ISMANAGER],
        MenuCollapsed: true,
        ActiveMenuKey: ["menu1_5", "menu1"],
        IsProfile: [1],
        component: <WorkerProfile title="Worker Profile" />
    },
    {
        path: PATH_PROVIDER.addSalonWorker,
        layout: 'penal',
        exact: true,
        auth: true,
        authType: [UserType.OWNER, UserType.ISMANAGER],
        MenuCollapsed: true,
        ActiveMenuKey: ["menu1_5", "menu1"],
        IsProfile: [1],
        component: <AddSalonWorker title="Salon And Worker" />
    },

    //Provider ticket
    {
        path: PATH_PROVIDER.TicketReplies,
        layout: 'penal',
        exact: true,
        auth: true,
        authType: [UserType.OWNER, UserType.ISMANAGER],
        MenuCollapsed: true,
        ActiveMenuKey: ["menu2_2", "menu2"],
        IsProfile: [1],
        component: <VendorTicketReply />
    },

    {
        path: PATH_PROVIDER.myCustomer,
        layout: 'penal',
        exact: true,
        auth: true,
        authType: [UserType.OWNER, UserType.ISMANAGER],
        MenuCollapsed: true,
        ActiveMenuKey: ["menu1_9", "menu1"],
        IsProfile: [1],
        component: <MyCustomer title="My Customer" />
    },

    {
        path: PATH_PROVIDER.jobPosted,
        layout: 'penal',
        exact: true,
        auth: true,
        authType: [UserType.OWNER, UserType.ISMANAGER],
        MenuCollapsed: true,
        ActiveMenuKey: ["menu1_6", "menu1"],
        IsProfile: [1],
        component: <JobPosted title="Job Posted" />
    },

    {
        path: PATH_PROVIDER.createJobPost,
        layout: 'penal',
        exact: true,
        auth: true,
        authType: [UserType.OWNER, UserType.ISMANAGER],
        MenuCollapsed: true,
        ActiveMenuKey: ["menu1_6", "menu1"],
        IsProfile: [1],
        component: <CreateJobPost title="Create Job Post" />
    },

    {
        path: PATH_PROVIDER.messages,
        layout: 'penal',
        exact: true,
        auth: true,
        authType: [UserType.OWNER, UserType.ISMANAGER],
        MenuCollapsed: true,
        ActiveMenuKey: ["menu1_7", "menu1"],
        IsProfile: [1],
        component: <Chat title="Messages" />
    },
    {
        path: PATH_PROVIDER.chatMessages,
        layout: 'penal',
        exact: true,
        auth: true,
        authType: [UserType.OWNER, UserType.ISMANAGER],
        MenuCollapsed: true,
        ActiveMenuKey: ["menu1_7", "menu1"],
        IsProfile: [1],
        component: <Chat title="Messages" />
    },
    {
        path: PATH_PROVIDER.addSalon,
        layout: 'penal',
        exact: true,
        auth: true,
        authType: [UserType.OWNER],
        MenuCollapsed: true,
        ActiveMenuKey: ["menu1_5", "menu1"],
        IsProfile: [1],
        component: <AddSalon title="Add Salon" />
    },
    {
        path: PATH_PROVIDER.becomeWorker,
        layout: 'penal',
        exact: true,
        auth: true,
        authType: [UserType.OWNER,UserType.WORKER, UserType.ISMANAGER],
        MenuCollapsed: true,
        ActiveMenuKey: ["menu1_5", "menu1"],
        IsProfile: [1],
        component: <BecomeWorker title="Become worker" />
    },
    {
        path: PATH_PROVIDER.addSalons,
        layout: 'penal',
        exact: true,
        auth: true,
        authType: [UserType.OWNER, UserType.ISMANAGER],
        MenuCollapsed: true,
        ActiveMenuKey: ["menu1_5", "menu1"],
        IsProfile: [1],
        component: <AddSalon title="Add Salon" />
    },

    {
        path: PATH_WORKER.addSalons,
        layout: 'penal',
        exact: true,
        auth: true,
        authType: [UserType.OWNER, UserType.ISMANAGER],
        MenuCollapsed: false,
        ActiveMenuKey: ["menu1_5", "menu1"],
        IsProfile: [1],
        IsManager: 1,
        component: <AddSalon title="Add Salon" />
    },

    {
        path: PATH_PROVIDER.updateSalonWorker,
        layout: 'penal',
        exact: true,
        auth: true,
        authType: [UserType.OWNER, UserType.ISMANAGER],
        MenuCollapsed: true,
        ActiveMenuKey: ["menu1_5", "menu1"],
        IsProfile: [1],
        component: <AddSalonWorker title="Add Salon" />
    },

    {
        path: PATH_PROVIDER.badges,
        layout: 'penal',
        exact: true,
        auth: true,
        authType: [UserType.OWNER, UserType.ISMANAGER],
        MenuCollapsed: true,
        ActiveMenuKey: ["menu1_8", "menu8"],
        IsProfile: [1],
        component: <SalonBadges title="Salon Badges" />
    },

    {
        path: PATH_PROVIDER.notification,
        layout: 'penal',
        exact: true,
        auth: true,
        authType: [UserType.OWNER, UserType.ISMANAGER],
        MenuCollapsed: true,
        ActiveMenuKey: ["menu2_1", "menu2"],
        IsProfile: [1],
        component: <Notification title="Notification" />
    },
    {
        path: PATH_PROVIDER.subscription,
        layout: 'penal',
        exact: true,
        auth: true,
        authType: [UserType.OWNER, UserType.ISMANAGER],
        MenuCollapsed: true,
        ActiveMenuKey: ["menuid2_4", "menu2_4"],
        IsProfile: [1],
        component: <Subscription title="Subscription" />
    },
    {
        path: PATH_PROVIDER.contactSupport,
        layout: 'penal',
        exact: true,
        auth: true,
        authType: [UserType.OWNER, UserType.ISMANAGER],
        MenuCollapsed: true,
        ActiveMenuKey: ["menu2_2", "menu4"],
        IsProfile: [1],
        component: <ContactSupport title="Contact Support" />
    },
    {
        path: PATH_WORKER.contactSupport,
        layout: 'penal',
        exact: true,
        auth: true,
        authType: [UserType.WORKER],
        MenuCollapsed: false,
        ActiveMenuKey: ["menu2_2", "menu2"],
        IsProfile: [1],
        component: <WorkerContactSupport title="Contact Support" />
    },
    {
        path: PATH_CUSTOMER.contactSupport,
        layout: 'customer',
        exact: true,
        auth: true,
        MenuCollapsed: true,
        ActiveMenuKey: ["menu2_2", "menu2"],
        component: <CustomerContactSupport title="Contact Support" />
    },
    {
        path: PATH_CUSTOMER.messages,
        layout: 'customer',
        exact: true,
        auth: true,
        MenuCollapsed: true,
        ActiveMenuKey: ["menu1_5", "menu5"],
        component: <Chat title="Messages" />
    },
    {
        path: PATH_CUSTOMER.notification,
        layout: 'customer',
        exact: true,
        auth: true,
        MenuCollapsed: true,
        ActiveMenuKey: ["menuid2_1", "menu2_1"],
        component: <CustomerNotification title="Notification" />
    },
    {
        path: PATH_CUSTOMER.chatMessages,
        layout: 'customer',
        exact: true,
        auth: true,
        MenuCollapsed: true,
        ActiveMenuKey: ["menu1_5", "menu5"],
        component: <Chat title="Messages" />
    },
    {
        path: PATH_CUSTOMER.TicketReplies,
        layout: 'customer',
        exact: true,
        auth: true,
        MenuCollapsed: true,
        ActiveMenuKey: ["menu2_2", "menu2"],
        component: <CustomerTicketReply />
    },

    {
        path: "/provider/style",
        layout: 'penal',
        exact: true,
        auth: true,
        authType: [UserType.WORKER, UserType.OWNER],
        MenuCollapsed: true,
        ActiveMenuKey: [""],
        component: <StyleGuides />
    },
    // {
    //     path: PATH_CUSTOMER.dashboard,
    //     layout: 'customer',
    //     exact: true,
    //     auth: true,
    //     MenuCollapsed: true,
    //     ActiveMenuKey: [""],
    //     component: <CustomerDashboard title="Customer Dashboard" />
    // },
    {
        path: PATH_CUSTOMER.profile,
        layout: 'customer',
        exact: true,
        auth: true,
        MenuCollapsed: true,
        ActiveMenuKey: ["menu1_1", "menu1"],
        component: <CustomerProfile title="Customer Profile" />
    },
    {
        path: PATH_CUSTOMER.findService,
        layout: 'customer',
        exact: true,
        auth: true,
        MenuCollapsed: true,
        ActiveMenuKey: ["menu1_2", "menu1"],
        component: <FindService title="Find Service" />
    },
    {
        path: PATH_CUSTOMER.salonProfile,
        layout: 'customer',
        exact: true,
        auth: true,
        MenuCollapsed: true,
        ActiveMenuKey: ["menu1_2", "menu1"],
        component: <CustomerSalonProfile title="Find Service" />
    },
    {
        path: PATH_CUSTOMER.salonProfileView,
        layout: 'customer',
        exact: true,
        auth: true,
        MenuCollapsed: true,
        ActiveMenuKey: ["menu1_2", "menu1"],
        component: <CustomerSalonDetails title="Find Service" />
    },
    {
        path: PATH_CUSTOMER.bookingProcess,
        layout: 'customer',
        exact: true,
        auth: true,
        MenuCollapsed: true,
        ActiveMenuKey: ["menu1_2", "menu1"],
        component: <BookingProcess title="Booking Process" />
    },
    {
        path: PATH_CUSTOMER.myBooking,
        layout: 'customer',
        exact: true,
        auth: true,
        MenuCollapsed: true,
        ActiveMenuKey: ["menu1_3", "menu1"],
        component: <MyBooking title="My Booking" />
    },
    {
        path: PATH_CUSTOMER.customerRatingReview,
        layout: 'customer',
        exact: true,
        auth: true,
        MenuCollapsed: true,
        ActiveMenuKey: ["menu1_3", "menu1"],
        component: <CustomerRatingReview title="Customer Rating & Review" />
    },
    {
        path: PATH_CUSTOMER.paymentInfo,
        layout: 'customer',
        exact: true,
        auth: true,
        MenuCollapsed: true,
        ActiveMenuKey: ["menu1_3", "menu1"],
        component: <PaymentInfo title="Payment Info" />
    },
    {
        path: PATH_CUSTOMER.yourOrder,
        layout: 'customer',
        exact: true,
        auth: true,
        MenuCollapsed: true,
        ActiveMenuKey: ["menu1_3", "menu1"],
        component: <YourOrder title="Salon Payment Done" />
    },
    {
        path: PATH_CUSTOMER.favorites,
        layout: 'customer',
        exact: true,
        auth: true,
        MenuCollapsed: true,
        ActiveMenuKey: ["menu1_4", "menu1"],
        component: <Favorites title="Favorites" />
    },
    {
        path: "*",
        layout: 'front',
        exact: true,
        auth: true,
        MenuCollapsed: false,
        ActiveMenuKey: ["menu1_4", "menu1"],
        component: <ErrorPage title="404" />
    },
    {
        path: "/under-contruction",
        layout: 'panel',
        exact: true,
        auth: true,
        MenuCollapsed: false,
        ActiveMenuKey: ["menu1_4", "menu1"],
        component: <UnderConstruction title="under-contruction" />
    },
]

export default routes;