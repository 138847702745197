import React, { useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import Slider from "react-slick";
// import AuthHeader from "../../../layout/authlayout/AuthHeader";
// import AuthFooter from "../../../layout/authlayout/AuthFooter";
// import authStores from "../../../contexts/AuthProvider";
import { PATH_CUSTOMER, } from "../../../routes/path";
import { withTranslation } from "react-i18next";
import ThemeIcons from '../../../../assets/images/ThemeIcons';

const CustomerCongratulations = ({ t }) => {
    // const isRegistred = authStores((state) => state.isRegistred);
    const storedData = JSON.parse(localStorage.getItem('info'));

    const navigate = useNavigate();
    // const { state } = useLocation();
    const slider2 = useRef(null);

    const singleSlideScroll = {
        infinite: true,
        slidesToShow: 1,
        arrows: false,
        slidesToScroll: 1,
        speed: 300,
        fade: true,
        dots: false,
        autoplay: true,
    };

    // const authSlider = {
    //     slidesToShow: 1,
    //     arrows: false,
    //     slidesToScroll: 1,
    //     speed: 300,
    //     // infinite: false,
    //     fade: true,
    //     dots: false,
    // };

    const handleNext = () => {
        if (storedData && Object.keys(storedData).length) {
            navigate(`/customer/booking-process/${btoa(storedData.salon_id)}`, { state: { bookingData: storedData } });
        } else {
            navigate(PATH_CUSTOMER.profile)
        }
    }

    return (
        <div className="main-content-wrapper">
            <div className="left-part">
                <div className="upper_text flex_item_sb">
                    <div className="left-side flex-grow-1">
                        <h2 className="fs-40 fw-500 mb-3">
                            Awesome!
                        </h2>
                    </div>
                </div>
                <div className="lower_slider_content">
                    <div className="row justify-content-center">
                        <div className="col-lg-11 col-xl-10 col-xxl-10 col-xxxl-8">
                            <div className="slider_main position-relative">
                                <div className="slider_wrraper">
                                    {/* <div className="slider_shap">
                                           <img src={require("../../../../assets/images/slider_shap.png")} alt="" className="img-fluid" />
                                        </div> */}
                                    <Slider
                                        {...singleSlideScroll}
                                        className="slider_flex slide_gap30"
                                        ref={slider2}
                                    >
                                        <div className="slider_item">
                                            <div className="image text-center">
                                                <img
                                                    src={require("../../../../assets/images/physiotherapist.svg").default}
                                                    alt=""
                                                    className="img-fluid"
                                                />
                                            </div>
                                        </div>
                                        <div className="slider_item">
                                            <div className="image text-center">
                                                <img
                                                    src={require("../../../../assets/images/nail_salon_icon.svg").default}
                                                    alt=""
                                                    className="img-fluid"
                                                />
                                            </div>
                                        </div>
                                        <div className="slider_item">
                                            <div className="image text-center">
                                                <img
                                                    src={require("../../../../assets/images/massage_icon.svg").default}
                                                    alt=""
                                                    className="img-fluid"
                                                />
                                            </div>
                                        </div>
                                        <div className="slider_item">
                                            <div className="image text-center">
                                                <img
                                                    src={require("../../../../assets/images/hairdresser_icon.svg").default}
                                                    alt=""
                                                    className="img-fluid"
                                                />
                                            </div>
                                        </div>
                                        <div className="slider_item">
                                            <div className="image text-center">
                                                <img
                                                    src={require("../../../../assets/images/facial_care_icon.svg").default}
                                                    alt=""
                                                    className="img-fluid"
                                                />
                                            </div>
                                        </div>
                                    </Slider>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="right-part">
                <div className="main-body">
                    <div className="inner_form-content">
                        {/* <div className="text-logo">
                            <img
                                src={require("../../../../assets/images/text-type-logo'.png")}
                                alt=""
                            />
                        </div> */}
                        <div className="auth_common_tab_design">
                            <div className="form_wrapper otp_verification_form_wrapper">
                                <div className="account-created-success-ui">
                                    <div className="icon_wrapper mb-4 text-center">
                                        {ThemeIcons.icn_congratulations}
                                    </div>
                                    <h6 className="fs-18 fw-600 text_black mb-3 text-center">
                                        Congratulations & Welcome To The
                                    </h6>
                                    <h6 className="fs-18 fw-600 text_black mb-3 text-center">
                                        {/* {t('otp_verification_module.congratulations')} */}
                                        Mee Time Society!
                                    </h6>
                                    <p className="fs-16 fw-600 text_black50 text-center mb-4">
                                        {t('otp_verification_module.account_successfully_created')}
                                    </p>
                                    <Link
                                        className="btn-theme m-auto mb-3"
                                        onClick={(e) => {
                                            e.preventDefault(); // Prevent the default behavior
                                            handleNext(); // Call your custom function
                                        }}
                                        to="#"
                                    >
                                        Continue
                                    </Link>

                                </div>
                            </div>
                        </div>
                    </div>
                    {/* <AuthFooter /> */}
                </div>
            </div>

        </div>
    );
};

export default withTranslation()(CustomerCongratulations);
