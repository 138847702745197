import { Button, Divider } from 'antd'
import React from 'react'
import { withTranslation } from 'react-i18next';

const BadgeInfoCard = ({ t, badgeData, extraClass, icon, title, earnText, looseText, actionButton, handelModalOpen }) => {

    if (!badgeData) {
        return null;
    }

    const { name, image, earn_by, loose_by } = badgeData;
    return (
        <>
            <div className={`badge_info_card ${extraClass ? extraClass : ""} ${actionButton ? "card_with_button" : ""}`}>
                {title ? <h6 className="text-center text_black fs-15 fw-500 mb-3">{name}</h6> : <></>}
                {icon ? (<div className={`imgicon100 text-center mx-auto`}>
                    <img src={image} alt="Badge Image" width="100%" height="100%" /></div>
                ) : ""}
                <Divider className='theme_divider mt-4 mb-3' />
                <div className="text_content">
                    <div className='info'>
                        <h6 className='text-center text_black fs-15 fe-500 mb-2'>{t('badges.earn_it_by')}:</h6>
                        {earnText ? <p className='text-center text_black mb-0 fs-13 fw-400 lh1_5'>{earn_by}</p> : <></>}
                    </div>
                    <Divider className='theme_divider mt-4 mb-3' />
                    <div className='info'>
                        <h6 className='text-center text_black fs-15 fw-500 mb-2'>{t('badges.loose_it_by')}</h6>
                        {looseText ? <p className='text-center text_black mb-0 lh1_5 fs-13 fw-400'>{loose_by}</p> : <></>}
                    </div>
                </div>
            </div>

            {actionButton ?
                (badgeData.applied !== true ?
                    <div className="flex_item_cc"><Button className="btn-theme minw_260" onClick={() => handelModalOpen ? handelModalOpen(true) : ""}>{t('comman_text.apply')}</Button></div>
                    : <div className="flex_item_cc" ><Button className="btn-theme minw_260" style={{ color: "#fff", backgroundColor: "#0facd8" }} disabled onClick={() => handelModalOpen ? handelModalOpen(true) : ""}>{t('comman_text.applied')}</Button></div>) :
                <div className="flex_item_cc"><Button className="btn-theme minw_260" style={{ color: "#fff", backgroundColor: "#0facd8" }} disabled onClick={() => handelModalOpen ? handelModalOpen(true) : ""}>{t('comman_text.applied')}</Button></div>
            }

        </>
    )
}

export default withTranslation()(BadgeInfoCard);