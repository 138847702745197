import React, { useEffect, useState } from 'react'
import { Outlet } from 'react-router-dom';
import FrontHeader from './FrontHeader';
import FrontFooter from './FrontFooter';
import { HomePageLoader } from '../../view/common/HomePageLoader';


function FrontMaster(props) {
  // Toggle Sidebar
  const [IsMenuOpen, setIsMenuOpen] = useState();
  const ToggleSidebar = () => {
    if (IsMenuOpen) {
      setIsMenuOpen(false);
    } else {
      setIsMenuOpen(true);
    }
  }
  // Menu 
  const [openKeys, setOpenKeys] = useState(['menu1']);

  const rootSubmenuKeys = ['menu1', 'menu2', 'menu3', 'menu4', 'menu5', 'menu6', 'menu7'];
  const [loadingScreen, setLoadingScreen] = useState(true);
  useEffect(() => {
    setTimeout(() => {
      setLoadingScreen(false);
    }, 1000);
  }, []);

  const onSubmenuOpen = keys => {
    const latestOpenKey = keys.find(key => openKeys.indexOf(key) === -1);
    if (rootSubmenuKeys.indexOf(latestOpenKey) === -1) {
      setOpenKeys(keys);
    } else {
      setOpenKeys(latestOpenKey ? [latestOpenKey] : []);
    }

  };
  const OnMenuSelect = ({ key, keyPath }) => {
    const latestSelectedKey = keyPath.find(e => openKeys.indexOf(e) !== key);
    if (rootSubmenuKeys.indexOf(latestSelectedKey) === -1) {
      setOpenKeys(keyPath);
    } else {
      setOpenKeys(latestSelectedKey ? [latestSelectedKey] : []);
    }
  }

  return (
    <div className={`main-wrapper front_layout new_layout mt-0`}>
      <div className="main-layout-wrapper  ms-0">
        {
          loadingScreen ? (
            <HomePageLoader />
          ) :
            <>
              <FrontHeader ToggleSidebar={ToggleSidebar} />
              <main className="main-content-wrapper p-0">
                <Outlet />
              </main>
              <FrontFooter />
            </>
        }
      </div>
    </div>
  )
}

export default FrontMaster;