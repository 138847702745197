import axios from 'axios'
import Security from './Security'
import { authStore } from './components/contexts/AuthProvider';
export function AasAS(language) {
    const role = authStore((state) => state.accessToken);
}

function AxiosMiddleware(method, url, data, options) {

    const authStorage = JSON.parse(localStorage.getItem('auth-storage'));
    axios.defaults.headers.common['site'] = `meetime`;
    axios.defaults.headers.common['X-localization'] = `${localStorage.getItem('i18nextLng')}`;
    axios.defaults.headers.common['Authorization'] = authStorage?`Bearer ${authStorage.state.accessToken}`:"";
    const currentDate = new Date();

    // Get the time zone offset in minutes
    const offsetMinutes = currentDate.getTimezoneOffset();

    // Calculate the hours and minutes for the offset
    const offsetHours = Math.floor(Math.abs(offsetMinutes) / 60);
    const offsetMinutesRemainder = Math.abs(offsetMinutes) % 60;

    // Construct the offset string in the format "+HH:MM"
    const offsetString = `${offsetMinutes > 0 ? '-' : '+'}${offsetHours.toString().padStart(2, '0')}:${offsetMinutesRemainder.toString().padStart(2, '0')}`;
    axios.defaults.headers.common['tz'] = offsetString;

    if ((data.env != 'test' && url.search("env=test") == -1) && (options && options?.Accept != 'multipart/form-data') && method != 'get') {
        data.upt = authStorage?.state?.profileType
        data.csi = authStorage?.state?.salonDataId
        data = (new Security).encrypt(data);
    }

    if (data.env == 'test') {
        data.upt = authStorage?.state?.profileType;
        data.csi = authStorage?.state?.salonDataId;
    }

    if (method == 'get') {
        const params = { upt: authStorage?.state?.profileType, csi: authStorage?.state?.salonDataId };
        data = { ...data, params: { ...(data?.params || {}), ...params } };
    }

    switch (method) {
        case 'get':
            return axios.get(url, data, options);
        case 'post':
            return axios.post(url, data, options);
        case 'head':
            return axios.head(url, data, options);
        case 'patch':
            return axios.patch(url, data, options);
        case 'put':
            return axios.put(url, data, options);
        case 'delete':
            return axios.delete(url, { data: data, headers: options });
    }

}

axios.interceptors.response.use(
    (response) => {
        if (response.data.mac !== undefined) {
            response.data = (new Security).decrypt(response.data);
        }
        if (response.data?.document_status && response.data?.document_status != "2") {
            localStorage.setItem("document_status", parseInt(response.data?.document_status))
        }
        return response
    },
    (error) => {
        if (error.response.status === 423) {
        }
        if (error.response.status === 401) {
            localStorage.clear();
            window.location.reload()
        }
        if (error.response.status === 451) {
            window.location.replace(`${localStorage.getItem('i18nextLng')}` + '/under-construction')
        }
        if (error.response.status === 406) {
            window.location.replace(`${localStorage.getItem('i18nextLng')}` + '/comingsoon')
        }
        if (error.response.status === 412) {
            window.location.reload()
        }
        return Promise.reject(error);
    }
)

export function get(url, data = [], options = {}) {
    return AxiosMiddleware('get', url, data, options)
}
export function post(url, data = [], options = {}) {
    return AxiosMiddleware('post', url, data, options)
}
export function head(url, data = [], options = {}) {
    return AxiosMiddleware('head', url, data, options)
}
export function patch(url, data = [], options = {}) {
    return AxiosMiddleware('patch', url, data, options)
}
export function put(url, data = [], options = {}) {
    return AxiosMiddleware('put', url, data, options)
}
export function del(url, data = [], options = {}) {
    return AxiosMiddleware('delete', url, data, options)
}
export function setBearerToken(token) {
    axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
}
export function setVerifyToken(token) {
    axios.defaults.headers.common['VerifyToken'] = `${token}`;
}
export function setLocalizationLanguage(language) {
    axios.defaults.headers.common['X-localization'] = `${language}`;
    AasAS()

}
