import './App.scss';
import 'bootstrap';
import RoutePage from './components/routes';
import SocketContextProvider from './components/contexts/socket';
import LoadingScreen from './components/loading/LoadingScreen';
import { Suspense } from 'react';
import { I18nextProvider } from "react-i18next";
import i18n from "./i18n";
function App() {

  
  return (
    <SocketContextProvider>
      {/* <Suspense
        fallback={
          <LoadingScreen
            sx={{
              ...{
                top: 0,
                left: 0,
                width: 1,
                zIndex: 9999,
                position: "fixed",
              },
            }}
          />
        }
      > */}
      <I18nextProvider i18n={i18n}>

        <RoutePage />
      </I18nextProvider>

      {/* </Suspense> */}

    </SocketContextProvider>
  );
}

export default App;
