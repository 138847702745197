import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import LanguageSwitch from "../../view/common/LanguageSwitch";
import LanguagesModal from "../../view/modals/LanguagesModal";
import StylingAdvisor from "../../view/modals/StylingAdvisor";
import authStores from "../../contexts/AuthProvider";
import {
  PATH_AUTH,
  PATH_CUSTOMER,
  PATH_PROVIDER,
  PATH_WORKER,
} from "../../routes/path";
import { withTranslation } from "react-i18next";
import { Dropdown, Menu } from "antd";

function FrontHeader(props) {
  const { t } = props;
  const [modalOpen, setModalOpen] = useState(false);
  const isAuthenticated = authStores((state) => state.isAuthenticated);
  const profileType = authStores((state) => state.profileType);
  const is_profile_complete = authStores((state) => state.is_profile_complete);
  const become_worker_profile = authStores((state) => state.become_worker_profile);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const navigate = useNavigate();

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  function handleMenuClick(e) {
    if (e.key == 1) {
      navigate(PATH_AUTH.login)
    }
    if (e.key == 2) {
      navigate(PATH_AUTH.register)
    }
    if (e.key == 3) {
      navigate(PATH_AUTH.findSalon)
    }
    if (e.key == 4) {
      navigate(PATH_AUTH.root)
    }
    if (e.key == 5) {
      navigate(PATH_AUTH.applyForJob)
    }
    if (e.key == 6) {
      navigate(PATH_AUTH.aboutUs)
    }
    if (e.key == 7) {
      navigate(PATH_AUTH.contactUs)
    }
    if (e.key == 8) {
      navigate(PATH_AUTH.prices)
    }
    if (e.key == 9) {
      navigate(PATH_AUTH.faq)
    }
    if (e.key == 10) {
      if (profileType == 2 || profileType == 3) {
        navigate(is_profile_complete == 1 ? PATH_PROVIDER.dashboard : PATH_PROVIDER.salonProfile)
      } else if (profileType == 1) {
        navigate(become_worker_profile == 1 ? PATH_WORKER.dashboard : PATH_WORKER.profile)
      } else {
        navigate(PATH_CUSTOMER.profile)
      }
    }
  }

  const MenuItem = (
    <Menu
      onClick={handleMenuClick}
      defaultSelectedKeys={['2']}
      className="header-dropdown-card header-dropdown-content front_menu"
      items={[
        {
          type: 'group',
          className: 'header-dropdown-content',
          label: <div className="users-list flex-center-fs">
            <div className="user-text">
              <h6 className="fw-500 fs-16 mb-0 text-uppercase">{t("home_module.main_menu")}</h6>
            </div>
          </div>,
          children: [
            isAuthenticated && {

              key: '10',
              icon: <svg width="26" height="25" viewBox="0 0 26 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M12.377 17.7087L17.5853 12.5003L12.377 7.29199" stroke="#0DEFDA" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M16.127 3.125H20.2936C20.8462 3.125 21.3761 3.34449 21.7668 3.7352C22.1575 4.1259 22.377 5.20833 22.377 5.20833V5.20833C23.2043 10.0342 23.2043 14.9658 22.377 19.7917V19.7917C22.377 19.7917 22.2071 20.8245 21.7668 21.2648C21.3264 21.7051 20.8462 21.875 20.2936 21.875H16.127" stroke="#0FACD8" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M16.127 12.5L3.62695 12.5" stroke="#0DEFDA" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
              </svg>
              ,
              label: (<h6>{"Dashboard"}</h6>)
            },
            !isAuthenticated && {
              key: '1',
              icon: <svg width="26" height="25" viewBox="0 0 26 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M12.377 17.7087L17.5853 12.5003L12.377 7.29199" stroke="#0DEFDA" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M16.127 3.125H20.2936C20.8462 3.125 21.3761 3.34449 21.7668 3.7352C22.1575 4.1259 22.377 5.20833 22.377 5.20833V5.20833C23.2043 10.0342 23.2043 14.9658 22.377 19.7917V19.7917C22.377 19.7917 22.2071 20.8245 21.7668 21.2648C21.3264 21.7051 20.8462 21.875 20.2936 21.875H16.127" stroke="#0FACD8" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M16.127 12.5L3.62695 12.5" stroke="#0DEFDA" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
              </svg>
              ,
              label: (<h6>{t("comman_text.login")}</h6>)
            },
            !isAuthenticated && {
              key: '2',
              icon: <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M13.7204 3.125H9.37504C7.41086 3.125 6.42876 3.125 5.81857 3.73519C5.20837 4.34539 5.20837 5.32748 5.20837 7.29167V17.7083C5.20837 19.6725 5.20837 20.6546 5.81857 21.2648C6.42876 21.875 7.41085 21.875 9.37504 21.875H15.625C17.5892 21.875 18.5713 21.875 19.1815 21.2648C19.7917 20.6546 19.7917 19.6725 19.7917 17.7083V9.19628C19.7917 8.7705 19.7917 8.55761 19.7124 8.36618C19.6331 8.17475 19.4826 8.02421 19.1815 7.72314L15.1936 3.73519C14.8925 3.43412 14.742 3.28358 14.5505 3.20429C14.3591 3.125 14.1462 3.125 13.7204 3.125Z" stroke="#0DEFDA" stroke-width="1.5" />
                <path d="M9.375 13.542L15.625 13.542" stroke="#0FACD8" stroke-width="1.4" stroke-linecap="round" />
                <path d="M9.375 17.708L13.5417 17.708" stroke="#0FACD8" stroke-width="1.4" stroke-linecap="round" />
                <path d="M13.5416 3.125V7.29167C13.5416 8.27376 13.5416 8.76481 13.8467 9.0699C14.1518 9.375 14.6429 9.375 15.625 9.375H19.7916" stroke="#0DEFDA" stroke-width="1.2" />
              </svg>
              ,
              label: (<h6>{t("comman_text.register")}</h6>)
            },
            {
              key: '3',
              icon: <svg width="33" height="33" viewBox="0 0 33 33" fill="none" xmlns="http://www.w3.org/2000/svg">
                <circle cx="17.875" cy="15.125" r="9.625" transform="rotate(90 17.875 15.125)" stroke="#00FDB4" stroke-width="3" />
                <path d="M5.49992 27.4993L10.0833 22.916" stroke="#0facd8" stroke-width="3" stroke-linecap="round" />
              </svg>,
              label: (<h6>{t("comman_text.find_service")}</h6>)
            },
            {
              key: '4',
              icon: <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M5.83337 14.8861C5.83337 13.3021 5.83337 12.5101 6.15358 11.8139C6.47378 11.1177 7.07513 10.6022 8.27783 9.57134L9.4445 8.57134C11.6184 6.70803 12.7053 5.77637 14 5.77637C15.2948 5.77637 16.3817 6.70803 18.5556 8.57134L19.7222 9.57134C20.9249 10.6022 21.5263 11.1177 21.8465 11.8139C22.1667 12.5101 22.1667 13.3021 22.1667 14.8861V19.8333C22.1667 22.0331 22.1667 23.1331 21.4833 23.8165C20.7999 24.4999 19.6999 24.4999 17.5 24.4999H10.5C8.30015 24.4999 7.20021 24.4999 6.51679 23.8165C5.83337 23.1331 5.83337 22.0331 5.83337 19.8333V14.8861Z" stroke="#0DEFDA" stroke-width="1.3" />
                <path d="M16.9167 24.5V18.5C16.9167 17.9477 16.469 17.5 15.9167 17.5H12.0834C11.5311 17.5 11.0834 17.9477 11.0834 18.5V24.5" stroke="#0FACD8" stroke-width="1.3" stroke-linecap="round" stroke-linejoin="round" />
              </svg>
              ,
              label: (<h6>{t("comman_text.home")}</h6>)
            },
            {
              key: '5',
              icon: <svg width="33" height="33" viewBox="0 0 33 33" fill="none" xmlns="http://www.w3.org/2000/svg">
                <circle cx="17.875" cy="15.125" r="9.625" transform="rotate(90 17.875 15.125)" stroke="#00FDB4" stroke-width="3" />
                <path d="M5.49992 27.4993L10.0833 22.916" stroke="#0facd8" stroke-width="3" stroke-linecap="round" />
              </svg>,
              label: (<h6>{t("home_module.job_market")}</h6>)
            },
            {
              key: '6',
              icon: <svg xmlns="http://www.w3.org/2000/svg" width="23" height="23" viewBox="0 0 23 23" fill="none">
                <path d="M11.4991 21.0837C16.7918 21.0837 21.0824 16.7931 21.0824 11.5003C21.0824 6.2076 16.7918 1.91699 11.4991 1.91699C6.20638 1.91699 1.91577 6.2076 1.91577 11.5003C1.91577 16.7931 6.20638 21.0837 11.4991 21.0837Z" stroke="#0DEFDA" stroke-width="1.4" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M11.5 15.333V11.4997" stroke="#0FACD8" stroke-width="2.3" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M11.5 7.66699H11.509" stroke="#0FACD8" stroke-width="2.3" stroke-linecap="round" stroke-linejoin="round" />
              </svg>,
              label: (<h6>{t("comman_text.about_us")}</h6>)
            },
            {
              key: '7',
              icon: <svg xmlns="http://www.w3.org/2000/svg" width="23" height="22" viewBox="0 0 23 22" fill="none">
                <path d="M19.7444 9.46105C19.7444 7.21704 18.8665 5.06494 17.3037 3.47818C15.7408 1.89143 13.6212 1 11.4111 1C9.20096 1 7.08134 1.89143 5.51854 3.47818C3.95573 5.06494 3.07776 7.21704 3.07776 9.46105" stroke="#0DEFDA" stroke-width="1.4" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M12.9766 21.0957H9.85156C9.43716 21.0957 9.03973 20.9286 8.74671 20.6311C8.45368 20.3336 8.28906 19.93 8.28906 19.5093C8.28906 19.0885 8.45368 18.685 8.74671 18.3875C9.03973 18.09 9.43716 17.9229 9.85156 17.9229H12.9766C13.391 17.9229 13.7884 18.09 14.0814 18.3875C14.3744 18.685 14.5391 19.0885 14.5391 19.5093C14.5391 19.93 14.3744 20.3336 14.0814 20.6311C13.7884 20.9286 13.391 21.0957 12.9766 21.0957Z" stroke="#0DEFDA" stroke-width="1.4" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M19.75 16.8642V17.393C19.75 17.954 19.5305 18.492 19.1398 18.8887C18.7491 19.2854 18.2192 19.5083 17.6667 19.5083H14.0208M1 14.1545V12.1704C1.00007 11.6987 1.15542 11.2406 1.44136 10.8688C1.7273 10.4971 2.12742 10.233 2.57813 10.1186L4.39063 9.65748C4.48273 9.63419 4.57884 9.63251 4.67168 9.65255C4.76451 9.6726 4.85164 9.71385 4.92643 9.77318C5.00123 9.8325 5.06173 9.90835 5.10336 9.99495C5.14498 10.0816 5.16663 10.1767 5.16667 10.273V16.0509C5.16683 16.1474 5.14528 16.2428 5.10367 16.3296C5.06205 16.4164 5.00147 16.4925 4.92652 16.552C4.85158 16.6114 4.76426 16.6527 4.67121 16.6728C4.57816 16.6928 4.48184 16.691 4.38958 16.6675L2.57708 16.2074C2.12657 16.0928 1.72668 15.8287 1.44095 15.4569C1.15521 15.0852 1.00001 14.6271 1 14.1556V14.1545ZM21.8333 14.1545V12.1704C21.8333 11.6987 21.6779 11.2406 21.392 10.8688C21.106 10.4971 20.7059 10.233 20.2552 10.1186L18.4427 9.65748C18.3506 9.63419 18.2545 9.63251 18.1617 9.65255C18.0688 9.6726 17.9817 9.71385 17.9069 9.77318C17.8321 9.8325 17.7716 9.90835 17.73 9.99495C17.6884 10.0816 17.6667 10.1767 17.6667 10.273V16.0509C17.6665 16.1473 17.6881 16.2425 17.7296 16.3293C17.7712 16.416 17.8317 16.492 17.9065 16.5514C17.9813 16.6109 18.0685 16.6522 18.1614 16.6723C18.2543 16.6924 18.3505 16.6908 18.4427 16.6675L20.2552 16.2074C20.7059 16.093 21.106 15.8289 21.392 15.4572C21.6779 15.0854 21.8333 14.6273 21.8333 14.1556V14.1545Z" stroke="#0FACD8" stroke-width="1.4" />
              </svg>
              ,
              label: (<h6>{t("comman_text.contact_support")}</h6>)
            },
          ]
        },
        {
          type: 'group',
          className: 'header-dropdown-content',
          label: <div className="users-list flex-center-fs">
            <div className="user-text">
              <h6 className="fw-500 fs-16 mb-0 text-uppercase">{t("home_module.for_service_providers")}</h6>
            </div>
          </div>,
          children: [
            {
              key: '8',
              icon: <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22" fill="none">
                <path d="M12.12 7.93202C11.5414 7.93202 10.9627 7.91319 10.4016 7.93202C9.96318 7.95086 9.64755 8.2145 9.54234 8.62879C9.45466 8.96776 9.59495 9.36323 9.89305 9.57038C10.0684 9.7022 10.2788 9.73986 10.4892 9.73986C11.0328 9.73986 11.5764 9.73986 12.12 9.73986C13.4702 9.75869 14.6276 10.9263 14.6802 12.3763C14.7503 13.864 13.7333 15.1822 12.3831 15.3329C12.1376 15.3517 12.0674 15.4459 12.085 15.6907C12.1025 15.8978 12.1025 16.105 12.085 16.3121C12.0499 16.8017 11.7167 17.1784 11.2783 17.1972C10.8049 17.2349 10.4191 16.9147 10.3139 16.4063C10.2613 16.1426 10.2788 15.879 10.2788 15.6153C10.2788 15.4082 10.2087 15.3329 10.0158 15.3517C9.55988 15.3517 9.10396 15.3517 8.6305 15.3517C8.10444 15.3517 7.68359 14.9374 7.68359 14.4101C7.68359 13.8828 8.08691 13.4685 8.61297 13.4685C9.70016 13.4685 10.8049 13.4685 11.8921 13.4685C12.4357 13.4685 12.7864 13.1672 12.839 12.6776C12.8916 12.075 12.5409 11.6795 11.9447 11.6607C11.4011 11.6419 10.8575 11.6607 10.3139 11.6607C8.87599 11.6607 7.75374 10.512 7.70114 8.9301C7.64853 7.46123 8.64803 6.27484 9.96318 6.08652C10.2262 6.04886 10.2788 5.93587 10.2788 5.69106C10.2613 5.46508 10.2613 5.22027 10.3139 4.97545C10.4191 4.467 10.7873 4.16569 11.2783 4.20336C11.7167 4.24102 12.0499 4.61765 12.085 5.10728C12.1025 5.27676 12.085 5.46508 12.085 5.63456C12.085 6.06769 12.085 6.06769 12.4707 6.06769C12.8916 6.06769 13.3124 6.06769 13.7333 6.06769C14.2769 6.06769 14.6626 6.46316 14.6626 7.00927C14.6626 7.55539 14.2769 7.95086 13.7333 7.95086C13.2072 7.93202 12.6636 7.93202 12.12 7.93202Z" fill="#0FACD8" stroke="white" stroke-width="0.4" />
                <path d="M10.9 20.1667C16.3676 20.1667 20.8 15.8761 20.8 10.5833C20.8 5.2906 16.3676 1 10.9 1C5.43238 1 1 5.2906 1 10.5833C1 15.8761 5.43238 20.1667 10.9 20.1667Z" stroke="#0DEFDA" stroke-width="1.4" stroke-linecap="round" stroke-linejoin="round" />
              </svg>
              ,
              label: (<h6>{t("comman_text.prices")}</h6>)
            },
            {
              key: '9',
              icon: <img src={require("../../../assets/images/que.png")} alt="" />,
              label: (<h6>FAQ</h6>)
            },
          ]
        },
        {
          type: 'group',
          className: 'header-dropdown-content language_content',
          label: <LanguageSwitch />
        }
      ]}
    />
  );

  return (
    <>
      <header className="header-wrapper">
        <div className="container-fluid">
          <div className="header_content">
            <div className="logo_part">
              <Link to={"/"}>
                <img
                  src={require("../../../assets/images/meetime_new_logo.png")}
                  alt="logo"
                  className="logo"
                />
              </Link>
            </div>
            <div className="menu_part">
              <ul>
                {!isAuthenticated ? (
                  <>
                    <li className="d-lg-block d-none">
                      <Link to={"/login"}>
                        <img
                          src={require("../../../assets/images/icons/login-icon-large-while.svg").default}
                        />
                        {t("comman_text.login")}
                      </Link>
                    </li>
                    <li className="d-lg-block d-none">
                      <Link to={"/register"}>
                        <img
                          src={require("../../../assets/images/icons/register-icon-large-white.svg").default}
                          alt=""
                        />
                        {t("comman_text.register")}
                      </Link>
                    </li>
                  </>
                ) :
                ""
                  // <li className="">
                  //   {profileType == 2 || profileType == 3 ? (
                  //     <Link to={is_profile_complete == 1 ? PATH_PROVIDER.dashboard : PATH_PROVIDER.salonProfile}>
                  //       {t("comman_text.dashboard")}
                  //     </Link>
                  //   ) : profileType == 1 ? (
                  //     <Link to={become_worker_profile == 1 ? PATH_WORKER.dashboard : PATH_WORKER.profile}>{t("comman_text.dashboard")}</Link>
                  //   ) : (
                  //     <Link to={PATH_CUSTOMER.profile}>{t("comman_text.dashboard")}</Link>
                  //   )}
                  // </li>
                }

                <li className="d-lg-block d-none">
                  <LanguageSwitch />
                </li>
                {/* <li>
                  <Link to="/apply-for-job">
                    <svg width="36" height="34" viewBox="0 0 46 34" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M42.8086 2.38281L2.80859 2.38281" stroke="white" stroke-width="4.5" stroke-linecap="round" stroke-linejoin="round" />
                      <path d="M42.3729 16.6875L2.96875 16.6875" stroke="white" stroke-width="4.5" stroke-linecap="round" stroke-linejoin="round" />
                      <path d="M42.3729 31.0703L2.96875 31.0703" stroke="white" stroke-width="4.5" stroke-linecap="round" stroke-linejoin="round" />
                    </svg>
                  </Link>
                </li> */}
                <li>
                  <div className="btn_menu" style={{ 'cursor': "pointer" }}>
                    <Dropdown className='btn_menu' trigger={['click']} overlayStyle={{ paddingTop: '10px' }} overlayClassName="front_menu_wrapper" placement="bottomLeft" overlay={MenuItem}>
                      <svg width="36" height="34" viewBox="0 0 46 34" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M42.8086 2.38281L2.80859 2.38281" stroke="#0FACD8" stroke-width="4.5" stroke-linecap="round" stroke-linejoin="round" />
                        <path d="M42.3729 16.6875L2.96875 16.6875" stroke="#0FACD8" stroke-width="4.5" stroke-linecap="round" stroke-linejoin="round" />
                        <path d="M42.3729 31.0703L2.96875 31.0703" stroke="#0FACD8" stroke-width="4.5" stroke-linecap="round" stroke-linejoin="round" />
                      </svg>
                    </Dropdown>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="header_fancy_absolute_shape">
          <img
            src={require("../../../assets/images/header-fancy-shape.png")}
            alt=""
            className="img-fluid"
          />
        </div>
      </header>
      <LanguagesModal
        isModalVisible={isModalVisible}
        handleOk={handleOk}
        handleCancel={handleCancel}
      />
      <StylingAdvisor modalOpen={modalOpen} setModalOpen={setModalOpen} />
    </>
  );
}

export default withTranslation()(FrontHeader);
