import { Modal, Button } from 'antd'
import React from 'react'



const LanguagesModal = (props) => {
    const {handleCancel, handleOk, isModalVisible} = props
    return (
        <Modal className="modal-wrapper" width={800} centered visible={isModalVisible} onOk={handleOk} onCancel={handleCancel} closeIcon={<i class="ri-close-fill"></i>}
            title="Choose Country" 
            footer={[
                <button onClick={handleCancel} className="btn-theme btn-sm me-3">Return</button>,
                <button onClick={handleCancel} className="btn-theme btn-sm">Submit</button>,
            ]}
        >
            <ul className="row gx-3">
                <li className="col-md-4 col-sm-6">
                    <div class="form-check country-label active">
                        <input className="form-check-input" type="radio" value="" name='Country' id="flexCheckDisabled" />
                        <label className="form-check-label" htmlFor="flexCheckDisabled"><span className="flag-icon flag-icon-usa"></span> United States</label>
                    </div>
                </li>
                <li className="col-md-4 col-sm-6">
                    <div class="form-check country-label">
                        <input className="form-check-input" type="radio" value="" name='Country' id="flexCheckDisabled1" />
                        <label className="form-check-label" htmlFor="flexCheckDisabled1"><span className="flag-icon flag-icon-ita"></span> Italy</label>
                    </div>
                </li>
                <li className="col-md-4 col-sm-6">
                    <div class="form-check country-label">
                        <input className="form-check-input" type="radio" value="" name='Country' id="flexCheckDisabled2" />
                        <label className="form-check-label" htmlFor="flexCheckDisabled2"><span className="flag-icon flag-icon-esp"></span> Spain </label>
                    </div>
                </li>
                <li className="col-md-4 col-sm-6">
                    <div class="form-check country-label">
                        <input className="form-check-input" type="radio" value="" name='Country' id="flexCheckDisabled" />
                        <label className="form-check-label" htmlFor="flexCheckDisabled"><span className="flag-icon flag-icon-ind"></span> India </label>
                    </div>
                </li>
                <li className="col-md-4 col-sm-6">
                    <div class="form-check country-label">
                        <input className="form-check-input" type="radio" value="" name='Country' id="flexCheckDisabled1" />
                        <label className="form-check-label" htmlFor="flexCheckDisabled1"><span className="flag-icon flag-icon-guf"></span> French </label>
                    </div>
                </li>
                <li className="col-md-4 col-sm-6">
                    <div class="form-check country-label">
                        <input className="form-check-input" type="radio" value="" name='Country' id="flexCheckDisabled2" />
                        <label className="form-check-label" htmlFor="flexCheckDisabled2"><span className="flag-icon flag-icon-rus"></span> Russia </label>
                    </div>
                </li>
                <li className="col-md-4 col-sm-6">
                    <div class="form-check country-label">
                        <input className="form-check-input" type="radio" value="" name='Country' id="flexCheckDisabled2" />
                        <label className="form-check-label" htmlFor="flexCheckDisabled2"><span className="flag-icon flag-icon-abw"></span> Aruba </label>
                    </div>
                </li>
                <li className="col-md-4 col-sm-6">
                    <div class="form-check country-label">
                        <input className="form-check-input" type="radio" value="" name='Country' id="flexCheckDisabled2" />
                        <label className="form-check-label" htmlFor="flexCheckDisabled2"><span className="flag-icon flag-icon-afg"></span> Afghanistan </label>
                    </div>
                </li>
                <li className="col-md-4 col-sm-6">
                    <div class="form-check country-label">
                        <input className="form-check-input" type="radio" value="" name='Country' id="flexCheckDisabled2" />
                        <label className="form-check-label" htmlFor="flexCheckDisabled2"><span className="flag-icon flag-icon-ben"></span> Benin </label>
                    </div>
                </li>
                <li className="col-md-4 col-sm-6">
                    <div class="form-check country-label">
                        <input className="form-check-input" type="radio" value="" name='Country' id="flexCheckDisabled2" />
                        <label className="form-check-label" htmlFor="flexCheckDisabled2"><span className="flag-icon flag-icon-bgr"></span> Bulgaria </label>
                    </div>
                </li>
                <li className="col-md-4 col-sm-6">
                    <div class="form-check country-label">
                        <input className="form-check-input" type="radio" value="" name='Country' id="flexCheckDisabled2" />
                        <label className="form-check-label" htmlFor="flexCheckDisabled2"><span className="flag-icon flag-icon-dnk"></span> Denmark </label>
                    </div>
                </li>
                <li className="col-md-4 col-sm-6">
                    <div class="form-check country-label">
                        <input className="form-check-input" type="radio" value="" name='Country' id="flexCheckDisabled2" />
                        <label className="form-check-label" htmlFor="flexCheckDisabled2"><span className="flag-icon flag-icon-egy"></span> Egypt </label>
                    </div>
                </li>
                <li className="col-md-4 col-sm-6">
                    <div class="form-check country-label">
                        <input className="form-check-input" type="radio" value="" name='Country' id="flexCheckDisabled2" />
                        <label className="form-check-label" htmlFor="flexCheckDisabled2"><span className="flag-icon flag-icon-eri"></span> Eritrea </label>
                    </div>
                </li>
                <li className="col-md-4 col-sm-6">
                    <div class="form-check country-label">
                        <input className="form-check-input" type="radio" value="" name='Country' id="flexCheckDisabled2" />
                        <label className="form-check-label" htmlFor="flexCheckDisabled2"><span className="flag-icon flag-icon-gnq"></span> Equatorial Guinea </label>
                    </div>
                </li>
                <li className="col-md-4 col-sm-6">
                    <div class="form-check country-label">
                        <input className="form-check-input" type="radio" value="" name='Country' id="flexCheckDisabled2" />
                        <label className="form-check-label" htmlFor="flexCheckDisabled2"><span className="flag-icon flag-icon-isl"></span> Iceland </label>
                    </div>
                </li>
            </ul>
        </Modal>
    )
}

export default LanguagesModal
