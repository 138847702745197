import React, { useEffect } from 'react'
import { useTranslation } from "react-i18next";
import { Switch } from 'antd'


function LanguageSwitch({extraClass}) {

  const {i18n} = useTranslation();

  const switchValueClick = (check) => {
    if(check){
      localStorage.setItem('i18nextLng', 'en');
      i18n.changeLanguage("en")
    }else{
      localStorage.setItem('i18nextLng', 'dk');
      i18n.changeLanguage("dk")
    }
    // window.location.reload()
  }

  const lng = localStorage.getItem('i18nextLng');

  return (
    <div className={`language_switch_wrap ${extraClass ? extraClass : ""}`}>
        <Switch checkedChildren="DK" unCheckedChildren="EN" checked={lng === "en" ? true : false} onChange={switchValueClick}/>
    </div>
  )
}

export default LanguageSwitch;
