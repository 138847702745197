import { Spin } from 'antd'
import React from 'react'
import Lottie from "lottie-react";
import preloaderJson from "../../../assets/json/preloader.json";

export const HomePageLoader = () => {
    return (
        <div className={`custom_card home_loader d-flex align-items-center justify-content-center`} style={{ height: "100vh" , width:"100%",backgroundColor:"#ffffff",position:"fixed" , top:"0px" , zIndex:999}}>
            {/* <div className="custom_card_body flex_item_cc h-100"> */}
                <Lottie animationData={preloaderJson} loop={true} style={{ maxWidth: "450px", width: "100%" }} />
            {/* </div> */}
        </div>
    )
}
