import { Checkbox, Divider, Modal } from 'antd'
import React, { useState } from 'react'
import UploadImageCard from '../common/UploadImageCard';
import BadgeInfoCard from '../common/BadgeInfoCard';
import ThemeIcons from '../../../assets/images/ThemeIcons';

const StylingAdvisor = ({ modalOpen, setModalOpen }) => {
    // const [modalOpen, setModalOpen] = useState(false);
    return (
        <Modal
            className="modal-wrapper" wrapClassName='' width={500} centered onCancel={() => setModalOpen(false)} closeIcon={
                <svg width="38" height="35" viewBox="0 0 38 35" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g opacity="0.2">
                        <path d="M28.5586 9.02637L10.0586 25.7514" stroke="#22303D" stroke-opacity="0.8" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                        <path d="M10.0586 9.02637L28.5586 25.7514" stroke="#22303D" stroke-opacity="0.8" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                    </g>
                </svg>
            }
            open={modalOpen}
            title={<h2 className='text-center fs-22 lh1_2 text_black80 fw-700 mb-0'>Hair Cut/Styling Advisor</h2>}
            footer={""}
        >
            <div className='modal_content'>
                <BadgeInfoCard
                    icon={ThemeIcons.icn_adviser}
                    earnText={"You need to be able to advise customers to a suiting haircut and styling, according to the customerrshead shape, hair line and type. Then, 10 customers will have to confirm their satisfaction through their review. "}
                    looseText={"If over 30% of your customers weren't satisfied with their haircut, advised by you. "}
                    iconBgClass={"bg_gr_yellow"}
                    extraClass={"border-0"}
                     />
                    
                {/* <div className='styling_advisor_modal_content'>
                    <div className="common_icon_wrapper bg_gr_orange text-center mx-auto">
                        <img src={require("../../../assets/images/icons/hair_cut_icn.svg").default} alt="" className='img-fluid' />
                    </div>
                    <Divider className='theme_divider' />
                    <div className="text_content">
                        <div className='info'>
                            <h6 className='text-center fs-22 fw-600 text-dark mb-3'>Earn it by:</h6>
                            <p className='text-center fs-16 fw-400 text-dark mb-0'>
                                You need to be able to advise customers to a suiting haircut and styling, according to the customerrshead shape, hair line and type.
                                Then, 10 customers will have to confirm their satisfaction through their review.
                            </p>
                        </div>
                        <Divider />
                        <div className='info'>
                            <h6 className='text-center fs-22 fw-600 text-dark mb-3'>Loose it by</h6>
                            <p className='text-center fs-16 fw-400 text-dark mb-0'>
                                If over 30% of your customers weren't satisfied with their haircut, advised by you.
                            </p>
                        </div>
                    </div>
                </div> */}
            </div>
        </Modal >
    )
}

export default StylingAdvisor;